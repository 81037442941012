"use client";

import { Button } from "@/components/ui/button";
import { useClerk } from "@clerk/nextjs";
import clsx from "clsx";
import { ChevronRightIcon } from "lucide-react";
import { Loader2 } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

export default function Page() {
  const { openSignIn, openSignUp, user, loaded  } = useClerk();
  const router = useRouter();

  useEffect(() => {
    if (loaded && user) {
      router.push("/");
    }
  }, [loaded, router, user]);

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", () => {
      const interBubble =
        document.querySelector<HTMLDivElement>(".interactive")!;
      let curX = 0;
      let curY = 0;
      let tgX = 0;
      let tgY = 0;

      function move() {
        curX += (tgX - curX) / 20;
        curY += (tgY - curY) / 20;
        interBubble.style.transform = `translate(${Math.round(
          curX
        )}px, ${Math.round(curY)}px)`;
        requestAnimationFrame(() => {
          move();
        });
      }

      window.addEventListener("mousemove", (event) => {
        tgX = event.clientX;
        tgY = event.clientY;
      });

      move();
    });
  }, []);

  return (
    <div
      className={clsx(
        "fixed top-0 left-0 h-[100svh]  flex flex-col justify-center items-start w-full isolate overflow-hidden bg-white gradient-bg"
      )}
    >
      <div className="gradients-container fixed top-0 left-0 w-screen h-screen pointer-events-none">
        <div className="g1"></div>
        <div className="g2"></div>
        <div className="g3"></div>
        <div className="g4"></div>
        <div className="g5"></div>
        <div className="interactive"></div>
      </div>
      <main className="px-6 pb-24 sm:pb-32 lg:flex lg:px-16 fixed top-0 left-0 flex flex-col justify-center h-[100svh]">
        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
          <div className="mt-24 sm:mt-32 lg:mt-16">
            <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-white">
              <Link
                href="https://www.significantbit.se/"
                className="underline hover:opacity-50 transition-all opacity-50"
              >
                By Significant Bit.
              </Link>
              <ChevronRightIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
            </span>
          </div>
          <div className="flex flex-row items-center mt-6">
            <Image alt="" src={'/favicon-default/android-chrome-512x512.png'} width={100} height={100} />
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              Bit. Space
            </h1>
          </div>
          <p className="mt-6 text-lg leading-8 text-white">
            An office room booking platform with support for organizations,
            users and more.
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <Button
              onClick={() => {
                openSignUp({
                  afterSignInUrl: "/",
                  afterSignUpUrl: "/",
                });
              }}
              variant="secondary"
            >
              Get started today
            </Button>
            {loaded && user ? (
              <Button variant={'default'} disabled>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
               Logging you in...
              </Button>
            ) : (
              <Button
                onClick={() => {
                  openSignIn({
                    afterSignInUrl: "/",
                    afterSignUpUrl: "/",
                  });
                }}
                variant="default"
              >
                Log in{" "}
                <span aria-hidden="true" className="ml-2">
                  →
                </span>
              </Button>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
